import React, { Suspense } from 'react'
import Seo from '../components/Seo'
import { StyledContent } from '../components/layout/layout'
import styled from '@emotion/styled'
import { H1Bold, H2, H3 } from '../components/shared/typography'
import { graphql } from 'gatsby'
import { IProduct } from '../components/shop/shop.interface'
import { Col, Row } from 'antd'
import { StyledTextLink } from '../components/shared/button'
import ProductCard from '../components/shop/product-card'

const Container = styled.div`
  padding: 1rem;
  text-align: center;
  @media (max-width: 700px) {
    h1 {
      font-size: 36px;
      line-height: 40px;
    }
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
  }
`

const Newsletter: React.FC = ({ data: { productsList } }: any) => {
  const products: IProduct[] = productsList.nodes

  return (
    <StyledContent>
      <Seo
        title={'Nyhedsbrev'}
        image={'https://www.mansted-wine.dk/logo/mansted-wine-logo.jpg'}
        description={'Mansted wine nyhedsbrev. Tilmeld dig idag og modtag spændende vinnyheder og gode tilbud'}
      />
      <Container>
        <H1Bold>Tilmeld dig nyhedsbrevet</H1Bold>
        <H3 style={{ marginBottom: '2rem' }}>Tilmeld nyhedsbrevet i dag og modtag spændende vinnyheder og gode tilbud</H3>
        <Suspense fallback={<div>Loading...</div>}>
          <div className="klaviyo-form-XkyuAL"></div>
        </Suspense>
      </Container>
      {products.length > 0 && (
        <Container>
          <Row style={{ width: '1400px', maxWidth: '1400px', marginTop: '2rem' }}>
            <H2 style={{ textAlign: 'center', marginTop: '2rem', padding: '0.4rem 0', zIndex: 99, background: 'rgb(0, 0, 0, 0.6)', color: 'white' }}>{`Udvalgte vine`}</H2>
            <StyledTextLink style={{ width: '100%', textAlign: 'center', margin: '0' }} to={'/vine/'} state={{ showSale: true }}>
              Se alle
            </StyledTextLink>
            {products.map((product: IProduct, key: number) => (
              <Col xs={24} sm={12} md={8} key={key}>
                <ProductCard product={product} />
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </StyledContent>
  )
}

export default Newsletter

export const NewsPage = graphql`
  query {
    productsList: allShopifyProduct(limit: 3, filter: { variants: { elemMatch: { inventoryQuantity: { ne: 0 } } } }) {
      nodes {
        shopifyId
        descriptionHtml
        description
        tags
        collections {
          title
          metafields {
            value
          }
        }
        variants {
          storefrontId
          availableForSale
          compareAtPrice
          price
          inventoryQuantity
          selectedOptions {
            name
            value
          }
          image {
            gatsbyImageData
            originalSrc
          }
        }
        metafields {
          value
          key
        }
        featuredImage {
          originalSrc
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 800, formats: WEBP)
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        vendor
        title
      }
    }
  }
`
